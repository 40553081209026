@import 'styles/common';

.range {
  margin-bottom: 16px;

  &__track {
    display: flex;
    width: 100%;
    height: 36px;
  }

  &__background {
    align-self: center;
    width: 100%;
    height: 5px;
    border-radius: 4px;
  }

  &__thumb {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background-color: $white;
    border: 1px solid $content;
    border-radius: 50%;
  }

  &__prices {
    @include media-breakpoint-down(md) {
      width: 50%;
    }
  }
}
