@import 'styles/common';

.modal {
  position: fixed;
  top: -96px;
  left: 0;
  z-index: 50;
  display: flex;
  align-items: center;
  width: 100%;
  height: 96px;
  background: $white;
  box-shadow: none;

  p {
    margin-bottom: 0;
    margin-left: 12px;
    font-size: 20px;
    font-family: $font-family-heading;
    letter-spacing: 0;

    &:last-child {
      opacity: .4;
    }

    @include media-breakpoint-down(xs) {
      font-size: 16px;
    }
  }

  &_active {
    top: 0;
    box-shadow: 0 15px 20px rgb(30 30 30 / 15%);
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    @include media-breakpoint-up(md) {
      padding: 0 16px;
    }

    @include media-breakpoint-down(md) {
      flex-direction: column;
      justify-content: center;
    }
  }

  &__column {
    display: flex;

    @include media-breakpoint-down(md) {
      flex-direction: row-reverse;
      margin: 4px 0;
    }

    @include media-breakpoint-down(xs) {
      margin: 2px 0;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background: $graphite;
    border-radius: 50%;

    @include media-breakpoint-down(md) {
      display: none;
    }

    svg {
      width: 20px;
      height: 20px;
      fill: $white;
    }
  }

  &__text {
    display: flex;
    align-items: center;
  }

  &__button {
    display: flex;
    align-items: center;
    height: 40px;
    margin-left: 16px;
    padding: 0 16px;
    font-size: 14px;
    border-radius: 8px;
    cursor: pointer;
    letter-spacing: 0;

    @include media-breakpoint-down(xs) {
      height: 30px;
      padding: 0 10px;
    }

    &_dark {
      color: $white;
      background: $graphite;

      &:hover {
        background: rgba($graphite, .7);
      }
    }

    &_light {
      color: $content;
      background: $white;

      &:hover {
        opacity: .7;
      }
    }

    @include transition($transition-base);
  }

  @include media-breakpoint-down(xs) {
    height: 70px;
  }

  @include transition($transition-base);
}
