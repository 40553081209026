@import 'styles/common';

.card {
  display: flex;
  flex-direction: column;
  padding: 32px 40px;
  background: $white;
  border-radius: 8px;
  box-shadow: 0 0 20px rgb(0 0 0 / 10%);

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &_accessible {
    border: 2px solid $content;
    box-shadow: none;
  }

  &__title {
    margin-bottom: 12px;
    font-size: calc(var(--font-base) * 1.5);
    line-height: 1.2;
    font-family: var(--font-family-heading);

    &:hover {
      text-decoration: none;
      color: $hint;
    }

    @include mq(sm) {
      font-size: calc(var(--font-base) * 1.125);
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid $graphite-border;
    }

    @include mq(sm) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  &__text {
    display: inline-block;
    max-width: 80%;
    margin-bottom: 0;
    font-size: calc(var(--font-base) * .875);
    line-height: 1.2;
    color: $content;

    @include mq(sm) {
      max-width: 100%;
      margin-bottom: 8px;
    }
  }

  &__checkbox {
    @include mq(sm) {
      margin-left: auto;
    }
  }

  @include mq(sm) {
    padding: 16px 20px;
  }
}
