@import "styles/common";

.modalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.form {
  display: grid;
  gap: 1rem;
}

.paragraph {
  font-size: 16px;
  line-height: 26px;

  &__small {
    font-size: 14px;
    line-height: 24px;

    &-date {
      margin-bottom: 24px;
      font-size: 14px;
    }

    &-financing {
      padding: 0 48px;
      text-align: center;
    }

    @include media-breakpoint-down(xs) {
      &-financing {
        font-size: 13px;
      }
    }
  }
}

.title {
  margin-bottom: 16px;
  font-size: 32px;
  line-height: 1em;
  color: $additional-text;
}

@include media-breakpoint-down(xs) {
  .title {
    font-size: 20px;
  }
}

.button {
  margin: 16px 6px 0;
}

.select {
  width: 80%;
  margin-bottom: 8px;

  &__title {
    margin-bottom: 12px;
    font-size: 14px;
    line-height: 24px;
    text-align: left;
  }

  &_takeToWork {
    width: 60%;
  }
}

.input {
  max-width: 300px;
}

.comment {
  width: 470px;
  height: 100px;
  resize: none;
}

.work {
  max-height: 12rem;
  width: 80%;
  margin-top: 1rem;
  padding-left: 6px;
  font-size: 1.3rem;
  text-align: left;
  overflow: auto;

  &-txt {
    margin-top: 10px;
    margin-left: 20px;
    padding-right: 20px;
    padding-bottom: 5px;
    font-size: 1rem;
    text-align: left;
  }

  @include media-breakpoint-down(xs) {
    max-height: 10rem;
    width: 100%;
    font-size: 1.2rem;

    &-txt {
      margin-top: 0.8rem;
      margin-right: 15px;
      margin-left: 0;
      padding-right: 25px;
      font-size: 12px;
      text-align: left;
    }
  }
}

.work::-webkit-scrollbar-track {
  border-radius: 4px;
}

.work::-webkit-scrollbar {
  width: 6px;
}

.work::-webkit-scrollbar-thumb {
  background: $graphite;
  border-radius: 4px;
}

.work:hover::-webkit-scrollbar-thumb {
  background: $gray-placeholder;
}

.menu {
  position: absolute;
  z-index: 9999;
}
