@import 'styles/common';

.card {
  width: 100%;
  background-color: $white;
  border-radius: 8px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}
