@import 'styles/common';

.checkBox {
  padding-left: 0;

  &__label {
    font-weight: normal;
  }

  &__input {
    display: none;

    &:checked + .checkBox__label {
      .checkBox__icon {
        background-color: $accent;

        &_dark {
          background-color: $graphite;
          border-color: $graphite;
        }
      }

      .checkBox__svg {
        opacity: 1;
      }
    }
  }

  &__icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: var(--font-base);
    height: var(--font-base);
    margin-right: 8px;
    border: 1px solid $secondary;
    border-radius: 4px;
    flex-shrink: 0;
  }

  &__svg {
    width: calc(var(--font-base) * .625);
    height: calc(var(--font-base) * .5);
    margin-top: 2px;
    opacity: 0;
    fill: $white;
  }
}

.checkBox__input:not(:disabled) + .checkBox__label .checkBox__icon {
  &.checkBox__icon_dark:hover {
    border-color: $graphite;
  }
}

.checkBox__input:not(:disabled) + .checkBox__label:hover {
  cursor: pointer;
}
