@import 'styles/common';

.radioButton {
  display: flex;
  margin-bottom: 14px;
  padding-left: 0;
  gap: 10px;
  
  &__label {
    display: flex;
    align-items: center;
    font-weight: normal;
    font-size: 16px;
  }
  
  &__input {
    position: relative;
    width: 18px;
    height: 24px;
    appearance: none;

    &:checked::after {
      position: absolute;
      top: 50%;
      left: 50%;
      content: '';
      width: 15px;
      height: 15px;
      background-color: #3c4350;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      visibility: visible;
    }

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      content: '';
      width: 15px;
      height: 15px;
      border: 2px solid $bg-dark;
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

  