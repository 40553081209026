@import 'styles/common';

.select {
  font-size: 14px;

  &.is-invalid {
    background: $content-dark;

    .form__control {
      border-color: $danger;
    }
  }
}

.option_message {
  padding: 8px;
  font-size: 14px;
  text-align: center;
  color: $placeholder;
}

.dropdownIconWrap {
  position: absolute;
  top: 50%;
  right: 12px;
  width: 16px;
  height: 16px;
  transform: translateY(-50%);
}

.dropdownIcon {
  width: 100%;
  height: 100%;
  fill: $content-light;
  vertical-align: top;
}

.dropdownIconUp {
  width: 100%;
  height: 100%;
  margin-top: 3px;
  fill: $content-light;
  vertical-align: top;
}

.dropdownMenu {
  max-height: 230px;
  width: 100%;
  padding: 0 0 1rem;
  background-color: $white;
  border-top: none;
  border-color: $input-focus-border-color;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  overflow: hidden auto;
}

.dropdownItem {
  width: 100%;
  padding: calc(#{$gutters} / 4) $gutters;
  font-size: calc(var(--font-base) * .875);
  font-family: var(--font-family-base);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;

  &:hover,
  &:active {
    color: inherit;
    background: $additional-color-11;
  }

  &::first-letter {
    text-transform: capitalize;
  }

  &.active {
    color: $placeholder;

    &:hover,
    &:active {
      color: $placeholder;
      background: transparent;
    }
  }
}

.searchInputWrap {
  padding: calc(#{$gutters} / 4);
}

.searchInput {
  margin-bottom: calc(#{$gutters} / 4);
  padding-right: 12px;
  padding-left: 12px;
}

.selectInput {
  padding: 10px 40px 10px 16px;
  text-overflow: ellipsis;
}

.selectInputThesis {
  padding: 10px 40px 10px 11px;
  font-size: 14px;
  text-overflow: ellipsis;

  @include media-breakpoint-down(xs) {
    font-size: 12px;
  }
}
