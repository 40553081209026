@import 'styles/common';

.title {
  margin-bottom: 24px;
  font-size: calc(var(--font-base) * 1.25);
  line-height: 1.1;
  font-family: var(--font-family-heading);
  color: $gray-text;

  span {
    color: $content;
  }
}
