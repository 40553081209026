@import 'styles/common';

.sidebar {
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;

  &__mobile {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
    height: 100vh;
    padding: 0;
  }
}

.button {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 !important;
  padding: 16px 24px;
  font-size: calc(var(--font-base) * 1.125);
  line-height: 1.5;
  color: $content;
  background: $white;
  border-radius: 8px;
  letter-spacing: .0016px;
  filter: drop-shadow(0 0 30px rgb(0 0 0 / 10%));

  @include mq(sm) {
    margin-bottom: 0;
    padding: 16px;
  }

  &:hover {
    text-decoration: none;
    color: $content;
    transform: scale(1.05);
    box-shadow: 0 0 60px rgb(0 0 0 / 12%);
  }

  &_filter {
    border: none;

    @include mq(sm) {
      justify-content: center;
    }
  }

  &__icon {
    width: 22px;
    height: 22px;
    margin-right: 10px;
    fill: $graphite;

    &_filter {
      @include mq(sm) {
        margin-right: 0;
      }
    }
  }
}

.filter {
  padding: 24px 24px 16px;
  color: $content;
  background: $white;
  border-radius: 8px;
  filter: drop-shadow(0 0 30px rgb(0 0 0 / 10%));

  @include media-breakpoint-down(md) {
    display: none;
  }

  &__close {
    &::after {
      content: '\00d7';
      display: inline-block;
      font-size: 2.2rem;
    }
  }

  &__mobile {
    position: relative;
    top: 0;
    left: 0;
    z-index: 2;
    display: block;
    width: 100%;
    padding: 0 0 5rem;
    background: $white;
    border-radius: 0;
    overflow: auto;
  }

  &_accessible {
    border: 2px solid $content;
    filter: none;
  }

  &__title {
    display: inline-block;
    margin-bottom: 16px;
    font-size: calc(var(--font-base) * 1.25);
    line-height: 1.3;

    &_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: .8rem 1rem;
      color: $white;
      background: $graphite;

      h3 {
        margin: 0;
      }
    }
  }

  &__reset {
    display: inline-block;
    margin: 0 0 12px 12px;
    font-size: calc(var(--font-base) * .875);
    color: $gray-placeholder;
    border-bottom: 1px dashed $gray-placeholder;
    letter-spacing: var(--letter-spacing);
    cursor: pointer;

    @include media-breakpoint-down(md) {
      width: fit-content;
      margin-left: 0;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
    border-top: 1px solid $btn-outline;
    cursor: pointer;

    &:hover {
      .filter {
        &__text {
          color: $hint;
        }

        &__icon {
          fill: $hint;
        }
      }
    }

    &_mobile {
      padding: 1rem;
    }
  }

  &__text {
    display: inline-block;
    max-width: 90%;
    margin: 0;
    font-size: var(--font-base);
    line-height: 1.4;
  }

  &__icon {
    width: 16px;
    height: 16px;
    fill: $filter-icon;
    transform: rotate(90deg);

    &_rotate {
      transform: rotate(270deg);
    }

    @include transition($transition-base);
  }

  &__checks {
    display: flex;
    flex-direction: column;

    &_mobile {
      padding: 0 1rem;
    }
  }

  &__checkbox {
    max-width: 95%;
    margin-bottom: 14px;
  }

  &__buttons {
    position: absolute;
    bottom: 0;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1rem;
    background: $white;
    border-top: 1px solid $btn-outline;

    button {
      width: 45%;
      padding: .2rem;
      border-radius: 8px;
      outline: none;
    }

    &_reset {
      color: $content-light;
      background: none;
      border: 2px solid $btn-outline;
    }

    &_apply {
      color: $white;
      background: $graphite;
      border: 2px solid $graphite;
    }
  }
}
