@import "styles/common";

.search {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  padding: 8px;
  padding-left: 24px;
  background: $white;
  border-radius: 8px;
  box-shadow: 0 0 15px rgb(60 67 80 / 10%);

  &:hover {
    box-shadow: 0 0 15px rgb(60 67 80 / 30%);
  }

  &_accessible {
    border: 2px solid $content;
    box-shadow: none;
  }

  &__input {
    width: 100%;
    font-size: var(--font-base);
    line-height: 1.4;
    font-family: var(--font-family-heading);
    border: none;
    border-radius: 0;

    &:focus {
      background: transparent;
      border-color: $graphite;
    }
  }

  &__icon {
    width: 16px;
    height: 16px;
    fill: $hint;
  }

  &__btn {
    right: 8px;
    bottom: 8px;
    padding: 7px 23px;
    font-size: var(--font-base);
    line-height: 1.4;
    font-family: var(--font-family-base);
    color: $white;
    background: $graphite;
    border: 1px solid $graphite;
    border-radius: 6px;
    box-sizing: border-box;

    @include mq(sm) {
      padding: 7px;
    }
  }

  &__tag {
    margin-right: 8px;
    margin-bottom: 8px;
    padding: 6px 16px;
    font-size: calc(var(--font-base) * 0.875);
    line-height: 1.4;
    color: $content;
    background: transparent;
    border: 1px solid rgb(46 53 66 / 20%);
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      border-color: $black;
    }

    &_more {
      margin-right: 0;
      padding: 0;
      font-weight: 600;
      color: $black;
      background: transparent;
      border: none;

      &:hover {
        text-decoration: underline;
        color: $black;
      }
    }

    @include mq(sm) {
      display: none;
    }
  }
}
