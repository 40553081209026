@import "styles/common";

.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 14px 0 80px;
  font-size: calc(var(--font-base) * 0.875);
  gap: 1rem;
}

.nav-item {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 24px;
  background: none;
  border: 1px solid $graphite;
  border-radius: 8px;
  letter-spacing: var(--letter-spacing);

  &:disabled {
    color: $hint;
    border-color: $hint;
  }

  &:not(:disabled):hover {
    color: $white;
    background: $graphite;
  }

  @include transition($transition-base);

  @include mq(sm) {
    height: 32px;
    padding: 0 12px;
  }
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--size);
  height: var(--size);
  background-color: transparent;
  border: none;
  border-radius: 8px;

  --size: 40px;

  @include mq(sm) {
    --size: 32px;
  }

  &__number {
    cursor: pointer;

    @include transition($transition-base);
  }
}
